import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { initLocaleKeys } from '@/common/utils/locale';
import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/AmountWidget/constants';
import { setComponentsAsUnselectable } from '@/common/utils/setComponentsAsUnselectable';
import { getRole } from '@/common/utils/getRole';
import {
  setSelectableContainerGfpp,
  setSelectableTextGfpp,
} from '@/editor-app/manifest/manifestUtils/SelectableRepeater';
import { setInputFieldGfpp } from '@/editor-app/manifest/manifestUtils/InputField';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);

  widgetBuilder.set({
    displayName: t.donationForm.amount.displayName(),
    nickname: 'AmountWidgetWidget',
  });

  widgetBuilder.behavior().set({ removable: false });

  widgetBuilder
    .gfpp()
    .set('add', { behavior: 'HIDE' })
    .set('help', { id: helpIds.AMOUNT })
    .set('animation', { behavior: 'HIDE' });

  widgetBuilder
    .configureConnectedComponents(
      getRole(ComponentIds.Label),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountTitle.displayName(),
        });
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountBox),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountDefaultBtn),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemTextDefault),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountText.regular.displayName(),
        });
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY', preventHide: true });
        setSelectableTextGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountText.regular.gfpp.design(),
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemTextSelected),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountText.selected.displayName(),
        });
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY', preventHide: true });
        setSelectableTextGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountText.selected.gfpp.design(),
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemContainerDefault),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.behavior().set({
          closed: {
            selectable: true,
          },
          preventHide: true,
        });
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountOption.regular.displayName(),
        });
        setSelectableContainerGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountOption.regular.gfpp.design(),
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.itemContainerSelected),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.behavior().set({
          closed: {
            selectable: true,
          },
          preventHide: true,
        });
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountOption.selected.displayName(),
        });
        setSelectableContainerGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.amountOption.selected.gfpp.design(),
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountFieldInput),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.otherAmountField.displayName(),
        });
        setInputFieldGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.otherAmountField.gfpp.design(),
        );
      },
    );

  setComponentsAsUnselectable(widgetBuilder, [
    ComponentIds.Repeater,
    ComponentIds.ListBox,
    ComponentIds.multiStateItem,
    ComponentIds.RepeaterItem,
    ComponentIds.RepeaterItemInput,
    ComponentIds.CustomAmountField,
    ComponentIds.itemContainerSelectedState,
    ComponentIds.itemContainerDefaultState,
  ]);
};
