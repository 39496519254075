import { ConnectedComponentsBuilder } from '@wix/yoshi-flow-editor';
import { EditorSDK } from '@wix/platform-editor-sdk';

export const setSelectableTextGfpp = (
  innerWidgetBuilder: ConnectedComponentsBuilder,
  editorSDK: EditorSDK,
  mainActionLabel: string,
) => {
  innerWidgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: mainActionLabel,
      onClick: ({ detail: { componentRef } }) => {
        void editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef,
        });
      },
    })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });
};

export const setSelectableContainerGfpp = (
  innerWidgetBuilder: ConnectedComponentsBuilder,
  editorSDK: EditorSDK,
  mainActionLabel: string,
) => {
  innerWidgetBuilder.gfpp().set('mainAction1', {
    label: mainActionLabel,
    onClick: ({ detail: { componentRef } }) => {
      void editorSDK.editor.openNativeComponentPanel('', 'design', {
        componentRef,
      });
    },
  });
};
